/*
* Base
*/
.languageList {
  position: absolute;
  right: 0;
  top: 48px;
  z-index: 100;
  padding: var(--spacing-4);
  min-width: 232px;
  border: solid 1px var(--color-neutral-100);
  background: #fff;

  /* heading */
  & > span {
    display: block;
    padding: var(--spacing-2) 0;
  }

  & > ul {
    list-style: none;
    margin: 0;
    padding: var(--spacing-2) 0;
    border-top: solid 1px var(--color-neutral-100);
  }

  & li {
    padding: var(--spacing-1);
    margin-bottom: var(--spacing-2);

    &:last-child {
      margin-bottom: 0;
    }

    & a {
      display: flex;
      gap: var(--spacing-4);
      align-items: center;
      color: var(--color-neutral-700);
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: var(--color-neutral-600);
      }
    }
  }
}
